*, *::before, *::after {
    box-sizing: border-box;
}



  .gray-background-admin {
    background-color: #000000;
    height: 100vh;
    animation: colorChange 10s ease-in-out infinite;
  }
  
  
   

 
  
  
 
/*-------------------- Buttons --------------------*/
.btn {
    display: block;
    background-image: linear-gradient(to right, #5C258D 0%, #4389A2  51%, #5C258D  100%);
    color: #fff;
    text-decoration: none;
    margin: 20px 0;
    padding: 15px 15px;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
}
.btn::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.2s ease-in-out;
   
    border-radius: 5px;
}
.btn:hover::after {
    background: rgba(0, 0, 0, 0.1);
 
}
/* .btn-social {
    padding-left: 64px;
    position: relative;
    z-index: 1;
}
.btn-social .fa {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 50px;
    height: 100%;
    text-align: center;
    background: rgba(0, 0, 0, 0.1);
    line-height: 3.2;
    border-radius: 5px 0 0 5px;
} */


/*-------------------- Form --------------------*/
.form fieldset {
    border: none;
    padding: 0;
    margin: 20px 0;
    position: relative;
}
.form fieldset input {
    width: 100%;
    height: 48px;
    color: #000000;
    padding: 15px 40px 15px 15px;
    border-radius: 5px;
    font-size: 14px;
    outline: none !important;
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.2);
    vertical-align: top;
}
.form button {
    width: 100%;
    outline: none !important;
    background-image: linear-gradient(to right, #5C258D 0%, #4389A2  51%, #5C258D  100%);
    border: none;
    text-transform: uppercase;
    font-weight: bold;
  
}
/*-------------------- Signup --------------------*/
.signup-admin {
    position: absolute;
    top: 49%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    width: 900px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 25px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    display: flex;
    height: 500px ;
}

.signup-connect-admin, .signup-classic-admin {
    width: 50%;
    padding: 127px 50px ; 
}
.signup-connect-admin  {
    
    margin-top: 16px ;
    margin-left: -15px;
    background-image: url("/public/mainlogo.png") ; 
    background-size: contain ;
    background-repeat: no-repeat ;
}
 
.errorMessage{
    margin-top: -15px;
    height: 20px;
    text-align: center;
    color: tomato;
}
@media(max-width:850px) {
    .signup-admin{
        position: absolute;
        top: 49%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        width: 681px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 3px 25px rgba(0, 0, 0, 0.2);
        overflow: hidden;
        display: flex;
        height: 439px;
    }
    .signup-connect-admin,   .signup-classic-admin{
        width: 50%;
        padding: 94px 50px ; 
        
    
    }
    
    
  }
  .errorMessage{
    margin-top: -15px;
    height: 20px;
    text-align: center;
    color: orangered;
}

@media(max-width:700px) {
    .signup-admin{
        width: 360px;
        height: 330px;
    }
    .signup-connect-admin{
        width: 0%;
        padding: 0 -0;
        margin-top: 0 ;
        margin-left: 0;
        background-image: none; 
        background-size: contain ;
        background-repeat: no-repeat  ;
    }
     .signup-classic-admin {
       width: 101%;
       padding: 38px 50px;
    }
    
  }
