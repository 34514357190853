@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: text !important;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  scroll-behavior: smooth;
  -webkit-tab-highlight-color: transparent;
  scroll-behavior: smooth !important;
  -webkit-media-controls: none;
}

*::-webkit-media-controls-panel {
  display: none;
  -webkit-appearance: none;
}

*::--webkit-media-controls-play-button {
  display: none;
}

*::-webkit-media-controls-start-playback-button {
  display: none;
  -webkit-appearance: none;
}

video::-webkit-media-controls {
  display: none;
}

body {
  width: 100%;
  min-height: 100vh;
  font-family: "Poppins", sans-serif !important;
  background-color: rgb(0, 0, 0);
  user-select: none !important;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none !important;
  scroll-behavior: smooth !important;
}

html:focus-within {
  scroll-behavior: smooth;
}

video::-webkit-media-controls {
  display: none;
}

::-webkit-scrollbar-track {
  background: #000000;
}

::-webkit-scrollbar-thumb {
  background: rgb(255, 255, 255);
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(255, 255, 255);
  opacity: 90%;
}

html {
  scrollbar-width: none;
  scroll-behavior: smooth;
  scrollbar-color: #000000 transparent;
}

html:focus-within {
  scroll-behavior: smooth;
}

html:hover {
  scrollbar-color: #ffffff transparent;
}

::-webkit-scrollbar {
  width: 0px;
}

::root {
  scrollbar-width: 0px;
  scrollbar-width: none;
}

.app {
  -webkit-tab-highlight-color: transparent;
  scroll-behavior: smooth;
}
