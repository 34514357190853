.chart{
    margin-top: 0px;
}
.cards-admin{
    display: flex;
    justify-content: space-around;
    margin-left: 60px;
    margin-right: 60px;
    padding-top: 50px;
    border-radius: 30px;
    margin-bottom: 150px;
  }
  
  .admin-bg{
    background-color: #000000;
    height: 100vh
  }
  .graph-heading{
    font-family: 'ui-serif';
    position: absolute;
    left: 12%;
    top: 42%;

  }
  .revenue-report-button{
    position: absolute !important;
    right: 12%;
    top: 42%;
  }
  .date-filter{
    position: absolute;
    left: 5%;
    top: 15%;
  }



  .parentdashboardbuttons{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: auto;
    text-align: center;
    
  }


  .parentdashboardchild{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1em;
    height: 150px;
    color: #191970;
    border-radius: 8px;
   background-color: 	#FAF9F6;
    cursor: pointer;
  }

  .btndashboardcardtitle{

    font-size: 2rem;
    font-weight: 600;
  }


